<template>
  <div class="bill">
    <nav-bar :title="$t('奖金明细')" />

    <div class="tabs">
      <div class="tabs-item" :class="{'tabs-active': item.value == selectType.value}" v-for="(item, index) in typeList" :key="index"
        @click="typeChange(item)">
        {{ item.text }}
      </div>
    </div>

    <div class="select-row">
      <div class="select-item q-mt-sm">
        <div @click="showDateBefore(1)">{{ sdate }}</div>
        <div>~</div>
        <div @click="showDateBefore(2)">{{ edate }}</div>
      </div>
      <!-- <div class="select-item" @click="showType = true">
        <div>奖励类型：{{ selectType.text }}</div>
      </div> -->
    </div>

    <div class="list">
      <van-list v-if="list.length > 0" v-model:loading="loading" :finished="finished" :finished-text="$t('没有更多了')"
        :immediate-check="false" offset="10" @load="onLoad">
        <div class="bouns-item" v-for="item in list" :key="item.id">
          <div class="bouns-cell">
            <div>{{ $t(item.lxName) }}</div>
            <div style="color: #2cb065;">{{ item.jine }}</div>
          </div>
          <div class="bouns-cell bouns-label">
            <div>{{ abbr(item.yuserid, 4, 4) }}</div>
            <div>{{ item.edate }}</div>
          </div>
          <div class="bouns-cell bouns-label" v-if="item.bz != '-'">
            <div>{{ $t(item.bz) }}</div>
          </div>
        </div>
      </van-list>
      <empty :text="$t('暂无记录')" v-if="list.length == 0" />
    </div>

    <div class="cp-popup">
      <van-popup v-model:show="showType" round position="bottom">
        <van-picker :confirm-button-text="$t('确定')" :cancel-button-text="$t('取消')" :columns="typeList"
          @cancel="showType = false" @confirm="typeConfirm" />
      </van-popup>
    </div>

    <div class="cp-popup">
      <van-popup v-model:show="showDate" round position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          :confirm-button-text="$t('确定')" 
          :cancel-button-text="$t('取消')"
          @confirm="dateConfirm"
          @cancel="showDate = false"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import Empty from '@/components/Empty'
import { ref } from 'vue'
import CustomComponent from "@/components/CustomComponent";

import { Tab, Tabs, List, Popup, Picker, Sticky, DatetimePicker } from "vant";

export default {
  name: 'Bill',
  components: {
    Empty,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,

    [Sticky.name]: Sticky
  },
  setup() {
    return {
      us: ref(null),
      pageIndex: ref(1),
      list: ref([]),
      loading: ref(false),
      finished: ref(false),
      filterList: ref([]),
      shouyi: ref({}),

      active: ref(0),
      bonusNameList:ref([]),

      showType: ref(false),
      typeList: ref([]),
      selectType: ref({}),

      showDate: ref(false),
      dateActive: ref(1),
      sdate: ref(""),
      edate: ref(""),
      currentDate: ref(new Date)
    }
  },
  created() {
    this.typeList = [
      {text: this.$i18n.t('全部'), value: ''},
      {text: this.$i18n.t('静态释放'), value: 1},
      {text: this.$i18n.t('直推奖励'), value: 2},
      {text: this.$i18n.t('团队奖励'), value: 3},
    ]
    this.selectType = this.typeList[0]
    this.sdate = this.formatDate(new Date())
    this.edate = this.formatDate(new Date())

    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getdata()
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    back() {
      this.$router.back()
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    // 选择类型
    typeChange(e) {
      this.selectType = e
      this.pageIndex = 1
      this.list = []
      this.getdata()
    },
    showDateBefore(e) {
      this.dateActive = e
      this.showDate = true
    },
    // 选择时间
    dateConfirm(e) {
      if(this.dateActive == 1) {
        this.sdate = this.formatDate(e)
      }
      if(this.dateActive == 2) {
        this.edate = this.formatDate(e)
      }
      this.showDate = false
      this.pageIndex = 1
      this.list = []
      this.getdata()
    },
    onClick(e) {
      this.active = e
      this.pageIndex = 1
      this.list = []
      this.getdata()
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/Bonus/SourceList",
        {
          userid: _this.us.userid,
          uid: _this.us.id,
          pageIndex: _this.pageIndex,
          pageSize: 10,
          sdate: _this.sdate,
          edate: _this.edate,
          lx: _this.selectType.value,
        },
        (res) => {
          _this.loading = false
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data
          if (data.length == 0) {
            _this.finished = true
          } else {
            _this.finished = false
          }
          _this.list.push(...data)
        }
      )
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
  }
}
</script>

<style scoped>
.back-wrap {
  padding: 0 12px;
}

.list {
  padding: 10px 10px 0;
}

.list-name {
  display: flex;
}

.list-name>div {
  flex: 1;
  display: flex;
  align-items: center;
}

.list-name img {
  margin-left: 8px;
  width: 14px;
}

.list-val {
  padding: 10px 0;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.list-val>div {
  flex: 1;
}

.cell {
  margin-top: 15px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
}

.total {
  padding: 15px 15px 0;
}

.total-row {
  margin-top: 6px;
  display: flex;
}

.total-item {
  padding-bottom: 10px;
  flex: 1;
  text-align: center;
  border-right: 1px solid rgba(231, 228, 228, 0.7);
  border-bottom: 1px solid rgba(231, 228, 228, 0.7);
}

.total-item:last-child {
  border-right: 0;
}

.item-border {
  border-right: 1px solid rgba(255, 255, 255, .5);
  border-bottom: 1px solid rgba(255, 255, 255, .5);
}

.total-title {
  font-weight: bold;
  font-size: 15px;
}

.bouns-item {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  background-color: rgb(45, 38, 56);
}

.bouns-cell {
  padding: 4px 0;
  display: flex;
  justify-content: space-between;
}

.bouns-label {
  color: #aaaaaa;
}

.cp-popup /deep/ .van-picker {
  background: #121C2E !important;
}

.cp-popup /deep/ .van-picker__mask {
  background-size: 100% 0 !important;
}

.cp-popup /deep/ .van-picker__frame::after {
  border-top: 1px solid rgba(85, 80, 80, 0.6);
  border-bottom: 1px solid rgba(85, 80, 80, 0.6);
}

.cp-popup /deep/ .van-picker-column__item {
  color: #fff;
}

.cp-popup /deep/ .van-picker__confirm {
  color: #884DDA;
}

.cp-popup /deep/ .van-picker__title {
  color: #fff;
}

.select-row {
  padding: 0 15px;
}

.select-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #121C2E;
  color: #fff;
  font-size: 13px;
}

.tabs {
  margin: 0 10px;
  padding: 6px;
  border-radius: 99px;
  display: flex;
  height: 40px;
  box-sizing: border-box;
  background-color: #121C2E;
}

.tabs-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4E5F7E;
  font-size: 13px;
  text-align: center;
}

.tabs-active {
  color: #fff;
  border-radius: 99px;
  background: linear-gradient(180deg, #884DDA 0%, #482974 100%);
}
</style>